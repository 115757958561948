<template>
  <div class="background">
    <v-container class="d-flex" style="height: 100vh">
      <v-row class="text-center my-auto">
        <v-col cols="12">
          <div style="max-width: 374px" class="d-flex flex-column justify-center align-center">
            <v-card
              color="yellow"
              class="pa-4 rounded-lg"
              width="374"
            >
              <h1 class="font-weight-regular">S-Stamp</h1>
              <h2 class="font-weight-light">สแกนส่วนลดลานจอด</h2>
            </v-card>
            <div class="arrow-down mt-4"></div>
            <v-card
                max-width="374"
                class="my-auto mt-4"
            >
              <qrcode-stream style="width:100%" :camera="camera" @decode="onDecode" @init="onCameraChange"></qrcode-stream>
            </v-card>
            <div class="arrow-up mt-4"></div>
            <div class="mt-4 h2 white--text"><h1 class="font-weight-light">SCAN QR CODE</h1></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog.show"
      width="374"
      transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title>{{dialog.title}}</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="mt-4"><h2>{{dialog.text}}</h2></v-card-text>
            <v-card-actions>
                <v-btn
                    class="white--text ml-auto"
                    color="red"
                    fontColor="white"
                    @click="dialog.show = false"
                >OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
export default {
    data: () => ({
      dialog: {
        show: false,
        title: '',
        text: ''
      },
      isSubmitting: false,
      camera: 'front'
    }),
    methods: {
      async onDecode (decodedString) {
        const oldCamera = this.camera;
        if (!this.isSubmitting) {
          try {
            this.camera = 'off';
            this.isSubmitting = true;
            const ret = await axios.post('/discount/qrscan', {
              decodedString: decodedString
            });

            const data = ret.data;

            if (data.status == 0) {
              this.dialog.show = true;
              this.dialog.title = "Success!";
              this.dialog.text = "ได้รับส่วนลดแล้ว";
            } else {
              this.dialog.show = true;
              this.dialog.title = "Error";
              this.dialog.text = data.message;
            }
          } catch (e) {
            this.dialog.show = true;
            this.dialog.title = "Error";
            this.dialog.text = e;
          } finally {
            this.isSubmitting = false;
            this.camera = oldCamera;
          }
        }
      },
      onCameraChange (promise) {
        promise.catch(error => {
          const cameraMissingError = error.name === 'OverconstrainedError'
          const triedFrontCamera = this.camera === 'front'

          if (triedFrontCamera && cameraMissingError) {
            // no front camera on this device
            this.camera = 'auto';
          }
        })
      }
    },
    components: {
      QrcodeStream,
      QrcodeDropZone,
      QrcodeCapture
    },
}
</script>
<style>
.background {
  background-image: url('/static/background.png');
  background-size: cover;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 20px solid white;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid white;
}
</style>